import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { ContainerWrapper } from '../components/styled/Container';
import { UniversalLink } from '../components/UniversalLink';
import { SEO } from '../components/SEO';
import { BlogFooter } from '../components/BlogFooter';

interface Props {
  data: {
    mdx: {
      timeToRead: number;
      tableOfContents: {
        items: {
          url: string;
          title: string;
          items?: {
            url: string;
            title: string;
          }[];
        }[];
      };
      body: any;
      frontmatter: {
        title: string;
        date: string;
        slug: string;
        excerpt: string;
        featuredImage: {
          publicURL: string;
          childImageSharp: {
            fixed?: any;
            fluid?: any;
          };
        };
      };
    };
  };
}

const PostWrapper = styled(ContainerWrapper)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;
const PostInner = styled.article``;

const PostHeader = styled.div``;
const PostTitle = styled.h1``;
const Meta = styled.div`
  span:not(:last-child) {
    margin-right: 0.5rem;
    &::after {
      margin-left: 0.5rem;
      content: '|';
    }
  }
`;

const bt = ({ data }: Props) => {
  const post = data.mdx;
  return (
    <div>
      <SEO
        image={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
      />
      <Img
        style={{ maxHeight: '25vh' }}
        fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
      />

      <PostWrapper align='center' width='text'>
        <PostInner>
          <PostHeader>
            <UniversalLink to={'/blog'}>&larr; Back to blog</UniversalLink>
            <PostTitle>{post.frontmatter.title}</PostTitle>
            <Meta>
              <span>
                <UniversalLink to={'https://twitter.com/genehenson'}>
                  &copy;genehenson
                </UniversalLink>
              </span>
              <span>{post.frontmatter.date}</span>
              <span>{post.timeToRead} minute read</span>
            </Meta>
          </PostHeader>
          <div className='post'>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </PostInner>
        <BlogFooter
          url={post.frontmatter.slug}
          title={post.frontmatter.title}
        />
      </PostWrapper>
    </div>
  );
};

export default bt;

export const BlogPageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      timeToRead
      tableOfContents
      body
      frontmatter {
        title
        excerpt
        date(formatString: "D MMM YYYY")
        slug
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

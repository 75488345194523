import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

interface Props {
  url: string;
  title: string;
}

const Twitter = styled.a`
  color: #1da1f2;
`;

export const Share = ({ url, title }: Props) => {
  const encodedTitle = encodeURI(title);
  const fullUrl = `https://genehenson.com${url}`;
  return (
    <div>
      <h1 className={'h6'}>Share this</h1>
      <Twitter
        href={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${fullUrl}`}
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Share on Twitter'
      >
        <FontAwesomeIcon icon={faTwitter} size='2x' />
      </Twitter>
    </div>
  );
};

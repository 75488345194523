import React from 'react';
import styled from 'styled-components';
import { Share } from './Share';
import { UniversalLink } from './UniversalLink';

interface Props {
  url: string;
  title: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2rem;
`;

const Contact = styled.p`
  margin: 0;
  font-weight: 700;
`;

export const BlogFooter = ({ url, title }: Props) => {
  return (
    <Wrapper>
      <Share url={url} title={title} />
      <Contact>
        You think I got something wrong or missed the point entirely? You're
        probably right. Hit me up on{' '}
        <UniversalLink to={'https://twitter.com/genehenson'}>
          Twitter
        </UniversalLink>{' '}
        and let me know.
      </Contact>
    </Wrapper>
  );
};
